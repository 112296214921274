import axios from "@evercam/shared/api/client/axios"
import type {
  CamerasResponsePayload,
  CameraUpdateRequestPayload,
  PaginatedItems,
  AdminCamera,
  Project,
  Hdd,
  AdminUser,
  ProgressPhotosCreateUpdateRequestPayload,
  ProgressPhoto,
} from "@evercam/shared/types"
import type { StorageServersResponsePayload } from "@evercam/shared/types/storageServers"

export const AdminApi = {
  mediaHub: {
    getAllMedia(params) {
      return axios.get("/admin/media-hub", params)
    },
    deleteMedia(params) {
      return axios.delete("/admin/media-hub", { params })
    },
  },
  auditLogs: {
    getAuditLogs(params) {
      return axios.get("/admin/audit-logs", params)
    },
  },
  cameras: {
    getCamerasStats(params) {
      return axios.get("/admin/cameras/status-stats", params)
    },
    rebootCamera(cameraId) {
      return axios.post(`/admin/devices/camera/${cameraId}/reboot`)
    },
    focusCamera(cameraId, device) {
      return axios.post(`/admin/devices/${device}/${cameraId}/focus`)
    },
    getStorageServers(): Promise<StorageServersResponsePayload> {
      return axios.get("/admin/storage/servers")
    },
    getStorageDetails() {
      return axios.get("/admin/storage")
    },
    getSnapshotExtractions(params) {
      return axios.get("/admin/snapshot-extractions", params)
    },
    createCameras(payload) {
      return axios.post("/admin/cameras", payload)
    },
    deleteCamera(exid, payload) {
      return axios.post(`/admin/cameras/${exid}/delete`, payload)
    },
    testSnapshot(payload) {
      return axios.post("/admin/cameras/test-snapshot", payload)
    },
    getDuplicateCameras(params) {
      return axios.get("/admin/duplicate-cameras", params)
    },
    mergeDuplicateCameras(payload) {
      return axios.post("/admin/merge-cameras", payload)
    },
    getCameras(params): Promise<PaginatedItems<AdminCamera>> {
      return axios.get("/admin/cameras", params)
    },
    getCompares(params) {
      return axios.get("/admin/compares", params)
    },
    getCameraDetails(exid) {
      return axios.get(`/admin/cameras/${exid}`)
    },
    deleteSnapshots(cameraId, params) {
      return axios.delete(`admin/cameras/${cameraId}/snapshots`, {
        params,
      })
    },
    refreshDeviceSettings(cameraId) {
      return axios.post(
        `/admin/cameras/${cameraId}/device-settings/refresh`,
        {}
      )
    },
    // (Azhar) Old save note code will be remove once we migrate all old saved camera notes
    saveNote(cameraId, params) {
      return axios.post(`/admin/cameras/${cameraId}/save-note`, params)
    },
    saveCameraNote(cameraId, params) {
      return axios.post(`/admin/cameras/${cameraId}/note`, params)
    },
    update(
      cameraId: string,
      params: CameraUpdateRequestPayload
    ): Promise<CamerasResponsePayload> {
      return axios.patch(`/admin/cameras/${cameraId}`, params)
    },
  },
  companies: {
    getCompanies(params) {
      return axios.get("/admin/companies", params)
    },
    updateCompany(id, params) {
      return axios.patch(`/admin/companies/${id}`, params)
    },
    deleteCompany(params) {
      return axios.delete("/admin/companies", { params })
    },
    reSyncCompanies(params) {
      return axios.post("/admin/companies/resync", params)
    },
  },
  emailing: {
    performEmailing(template, params) {
      return axios.post(`/admin/mailing/${template}`, params)
    },
    suppressions(params) {
      return axios.get("/admin/mailing/suppressions", params)
    },
    deleteSuppression(id) {
      return axios.delete(`/admin/mailing/suppressions/${id}`)
    },
  },
  extractions: {
    updateCloudRecordings(cameraId, params) {
      return axios.post(`/admin/cameras/${cameraId}/cloud-recordings`, params)
    },
    addExtraction(cameraId, params) {
      return axios.post(`admin/cameras/${cameraId}/extractions`, params)
    },
    getExtractionStatus(cameraId, extractionId, params) {
      return axios.get(
        `admin/cameras/${cameraId}/extractions/${extractionId}`,
        {
          params,
        }
      )
    },
    deleteSnapshotExtraction(cameraId, extractionId, params) {
      return axios.delete(
        `admin/cameras/${cameraId}/extractions/${extractionId}`,
        {
          params,
        }
      )
    },
  },
  gateReport: {
    getGateReportProjects(params) {
      return axios.get("/admin/gate-report", params)
    },
    getGateReportProject(projectExid) {
      return axios.get(`/admin/projects/${projectExid}`)
    },
  },
  kits: {
    getAllKits(params) {
      return axios.get("/admin/kits", params)
    },
    createKit(params) {
      return axios.post("/admin/kits", params)
    },
    updateKit(id, params) {
      return axios.patch(`/admin/kits/${id}`, params)
    },
    deleteKit(id) {
      return axios.delete(`/admin/kits/${id}`)
    },
  },
  logs: {
    getAllCamerasStatusLogs(params) {
      return axios.get("/admin/cameras-logs", params)
    },
    getCameraLogs(cameraId, payload) {
      return axios.get(`/admin/cameras/${cameraId}/audit-logs`, payload)
    },
  },
  nvrs: {
    getNvrs(params) {
      return axios.get("/admin/nvrs", params)
    },
    rebootNVR(cameraId) {
      return axios.post(`/admin/devices/nvr/${cameraId}/reboot`)
    },
  },
  offlineCameras: {
    getSiteVisitCameras(params) {
      return axios.get("/admin/site-visit-cameras", params)
    },
    getOfflineCameras(params) {
      return axios.get("/admin/offline-cameras", params)
    },
    getOfflineCamerasLastNote(params) {
      return axios.get("/admin/offline-cameras/notes", { params: params })
    },
  },
  pa: {
    getPAUser(id: number, params = {}) {
      return axios.get(
        `/admin/users/${id}${"with_zoho" in params ? "?with_zoho" : ""}`
      )
    },
    getPAProjectPeriodEvents(id, payload) {
      return axios.get(`/admin/projects/${id}/stats`, { params: payload })
    },
    getPAUserEvents(id) {
      return axios.get(`/admin/users/${id}/events`)
    },
    getPAUserCameras(id) {
      return axios.get(`/admin/users/${id}/cameras`)
    },
    getPAUserProjects(id) {
      return axios.get(`/admin/users/${id}/projects`)
    },
    getPAUserPeriodEvents(id, payload) {
      return axios.get(`/admin/users/${id}/stats`, { params: payload })
    },
    getProjectUsers(id) {
      return axios.get(`/admin/projects/${id}/users`)
    },
    getPAProject(id, params) {
      return axios.get(
        `/admin/projects/${id}${"with_zoho" in params ? "?with_zoho" : ""}`
      )
    },
    getPAProjectCameras(id) {
      return axios.get(`/admin/projects/${id}/cameras`)
    },
  },
  projects: {
    getAll(params): Promise<PaginatedItems<Project>> {
      return axios.get("/admin/projects", params)
    },
    getProject(exid) {
      return axios.get(`/admin/projects/${exid}`)
    },
    getProjectsStats() {
      return axios.get("/admin/projects/status-stats")
    },
    createProject(params) {
      return axios.post("/admin/projects", params)
    },
    updateProject(projectExid, params) {
      return axios.put(`/admin/projects/${projectExid}`, params)
    },
    deleteProject(projectExid) {
      return axios.delete(`/admin/projects/${projectExid}`)
    },
    addToProject(projectExid, params) {
      return axios.post(`/admin/projects/${projectExid}/add-cameras`, params)
    },
  },
  routers: {
    getRouters(params): Promise<PaginatedItems<any>> {
      return axios.get("/admin/routers", params)
    },
    getRouterTypes() {
      return axios.get("/admin/router-types")
    },
    createRouter(params) {
      return axios.post("/admin/routers", params)
    },
    updateRouter(id, params) {
      return axios.patch(`/admin/routers/${id}`, params)
    },
    deleteRouter(id) {
      return axios.delete(`/admin/routers/${id}`)
    },
    getRouter(id) {
      return axios.get(`/admin/routers/${id}`)
    },
  },
  search: {
    search(params): Promise<PaginatedItems<any>> {
      return axios.get("/admin/search", params)
    },
  },
  hdds: {
    getHdds(payload) {
      return axios.get("/admin/hdds", { params: payload }) as Promise<Hdd[]>
    },
  },
  shareRequests: {
    getShareRequests(params) {
      return axios.get("/admin/camera-share-requests", params)
    },
    deleteShareRequest(params) {
      return axios.delete("/admin/camera-share-requests", { params: params })
    },
    updateShareRequestStatus(params) {
      return axios.patch("/admin/camera-share-requests", params)
    },
  },
  shares: {
    createShares(camera_exid, payload) {
      return axios.post(`/admin/cameras/${camera_exid}/shares`, payload)
    },
    getShares(params) {
      return axios.get("/admin/camera-shares", params)
    },
    deleteShares(ids) {
      return axios.delete("/admin/camera-shares", { params: ids })
    },
  },
  sims: {
    deleteSim(simId) {
      return axios.delete(`/admin/sims/${simId}`)
    },
    createSim(params) {
      return axios.post("/admin/sims", params)
    },
    updateSim(simId, params) {
      return axios.patch(`/admin/sims/${simId}`, params)
    },
    getSims(params) {
      return axios.get("/admin/sims", params)
    },
    getSimProviders() {
      return axios.get("/admin/sim-providers")
    },
    getBillPayers() {
      return axios.get("/admin/sims/bill-payers")
    },
  },
  sms: {
    sendSms(number, params) {
      return axios.post(`/admin/sims/${number}/sms`, params)
    },
    getSimHistory(number) {
      return axios.get(`/admin/sims/${number}/sms`)
    },
    getSms(params) {
      return axios.get("/admin/sms", params)
    },
  },
  progressPhotos: {
    getProgressPhotos(params) {
      return axios.get("/admin/progress-photos", params)
    },
    getProgressPhotosHistory(params) {
      return axios.get("/admin/progress-photos/history", params)
    },
    getProgressPhotoTemplate({ id }) {
      return axios.get(`/admin/progress-photos/history/${id}/email`)
    },
    update(
      id: string,
      payload: ProgressPhotosCreateUpdateRequestPayload
    ): Promise<ProgressPhoto> {
      return axios.patch(`/admin/progress-photos/${id}`, payload)
    },
  },
  users: {
    updateUser(id, params) {
      return axios.put(`/admin/users/${id}`, params)
    },
    getAdmins(params) {
      return axios.get("/admin", params)
    },
    deleteAdmin(email) {
      return axios.put(`/admin/users/${email}/revoke-admin`)
    },
    makeAdmin(email) {
      return axios.put(`/admin/users/${email}/grant-admin`)
    },
    deleteUser(email, params) {
      return axios.post(`/users/${email}/delete`, params)
    },
    impersonateUser(email) {
      return axios.post(`/admin/users/${email}/impersonate`)
    },
    getUsers(params): Promise<PaginatedItems<AdminUser>> {
      return axios.get("/admin/users", params)
    },
    getUserPasswordResetLink(email: string) {
      return axios.post(`/admin/users/${email}/password/reset`)
    },
  },
}
